import React, { useRef, useState } from 'react';
import "./Testimonials.css"
import BigTitle from '../BigTitle/BigTitle';
import Description from '../Description/Description';

import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import right from "../../assets/clients-section/Frame (1).svg";
import left from "../../assets/clients-section/Frame.svg";
import img from "../../assets/about/36.smile.png";

import client1 from "../../assets/about/client1-kesri.png";
import client2 from "../../assets/about/client-2 kesri.png";
import client3 from "../../assets/about/client-3 kesri.png";

import img3 from "../../assets/about/left-test.svg";
import img4 from "../../assets/about/right-test.svg";


const Testimonials = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const slider = useRef(null)
    const settings = {
        arrows: false,
        dots: false,
        infinite: true,
        autoplay: true,
        centerPadding: "0px",
        centerMode: true,
        autoplaySpeed: 3000,
        pauseOnHover: false,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => setCurrentSlide(next),
    };
    return (
        <div className="c-container">
            <section className="testimonials-section">
                <div className="big-title">
                    <div className="big-title-content">
                        <p className='big-title-heading'>Our Happy Client's</p>
                    </div>
                    <div className="big-title-img">
                        <img src={img} alt={"smile"} />
                    </div>
                </div>
                <div className="service-section-header">
                    <Description desc="Harnessing the sun, powering a brighter future" />
                    <div className="service-section-btns">
                        <img src={left} alt="left" onClick={() => slider?.current?.slickPrev()} />
                        <p>{`0${currentSlide + 1}/03`}</p>
                        <img src={right} alt="right" onClick={() => slider?.current?.slickNext()} />
                    </div>
                </div>
                <Slider ref={slider} {...settings}>
                    <div>
                        <div className="testimonial-card-container">
                            <div className="testimonial-card">
                                <div className="testimonial-img">
                                    <img src={client2} alt="Our Clients" />
                                </div>
                                <div className="testimonial-content">
                                    <div className="testimonial-logo-1">
                                        <img src={img3} alt="testimonial logo" />
                                    </div>
                                    <div className="testimonial-info">
                                        <p className="testimonial-desc">Delivery and overall procedure was quick. With a curated collection of panels spanning styles and functionalities, they redefine what it means to go solar. Kesrinandan earns my highest praise</p>
                                        <h5 className="testimonial-author">Amar Singh Rathore</h5>
                                        <h5 className="testimonial-position">Jaipur</h5>
                                    </div>
                                    <div className="testimonial-logo-2">
                                        <img src={img4} alt="testimonial logo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="testimonial-card">
                            <div className="testimonial-img">
                                <img src={client1} alt="Our Clients" />
                            </div>
                            <div className="testimonial-content">
                                <div className="testimonial-logo-1">
                                    <img src={img3} alt="testimonial logo" />
                                </div>
                                <div className="testimonial-info">
                                    <p className="testimonial-desc">Highly recommended! From browsing to delivery, flawless experience. Kesrinandan is my go-to for solar needs</p>
                                    <h5 className="testimonial-author"> Vibhuti Singh Deora</h5>
                                    <h5 className="testimonial-position">Jaipur</h5>
                                </div>
                                <div className="testimonial-logo-2">
                                    <img src={img4} alt="testimonial logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="testimonial-card">
                            <div className="testimonial-img">
                                <img src={client3} alt="Our Clients" />
                            </div>
                            <div className="testimonial-content">
                                <div className="testimonial-logo-1">
                                    <img src={img3} alt="testimonial logo" />
                                </div>
                                <div className="testimonial-info">
                                    <p className="testimonial-desc">Their reselling service made it easy to find the perfect panels for my needs. The process was seamless, and the panels arrived in excellent condition. Highly recommend!.</p>
                                    <h5 className="testimonial-author">Nalin Soran</h5>
                                    <h5 className="testimonial-position">Kota</h5>
                                </div>
                                <div className="testimonial-logo-2">
                                    <img src={img4} alt="testimonial logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </section>
        </div>
    )
}

export default Testimonials