import React, { useState } from 'react';
import "./OurBrandsSection.css";
import Slider from "react-slick";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import img from '../../assets/brands/axitec.webp';
import img1 from '../../assets/brands/panasonic.webp';
import img2 from '../../assets/brands/livguard.webp';
import img3 from '../../assets/brands/rec.webp';
import img4 from "../../assets/brands/Group 113.svg";
import img5 from "../../assets/brands/Group 114.svg";
import img6 from "../../assets/brands/Group 115.svg";
import img7 from "../../assets/brands/Group 116.svg";

const placeholder = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw=='; 

const OurBrandsSection = () => {
    const [imgLoaded, setImgLoaded] = useState(false);
    const [img1Loaded, setImg1Loaded] = useState(false);
    const [img2Loaded, setImg2Loaded] = useState(false);
    const [img3Loaded, setImg3Loaded] = useState(false);
    const [img4Loaded, setImg4Loaded] = useState(false);
    const [img5Loaded, setImg5Loaded] = useState(false);
    const [img6Loaded, setImg6Loaded] = useState(false);
    const [img7Loaded, setImg7Loaded] = useState(false);

    const settings = {
        arrows: false,
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2500,
        pauseOnHover: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
        ]
    };

    return (
        <div className="c-container">
            <div className='our-brands-section'>
                <div className="brands">
                    <div className="partner-logos logo-show">
                        <div className="brands-item">
                            <LazyLoadImage
                                src={imgLoaded ? img : placeholder}
                                alt="axitec logo"
                                className='brands-logo1'
                                onLoad={() => setImgLoaded(true)}
                            />
                        </div>
                        <div className="brands-item">
                            <LazyLoadImage
                                src={img3Loaded ? img3 : placeholder}
                                alt="Rec logo"
                                className='brands-logo4'
                                onLoad={() => setImg3Loaded(true)}
                            />
                        </div>
                        <div className="brands-item">
                            <LazyLoadImage
                                src={img1Loaded ? img1 : placeholder}
                                alt="Anchor by panasonic"
                                className='brands-logo2'
                                onLoad={() => setImg1Loaded(true)}
                            />
                        </div>
                        <div className="brands-item">
                            <LazyLoadImage
                                src={img2Loaded ? img2 : placeholder}
                                alt="Livguard logo"
                                className='brands-logo3'
                                onLoad={() => setImg2Loaded(true)}
                            />
                        </div>
                    </div>
                    <div className='logo-hide'>
                        <Slider {...settings} >
                            <div>
                                <div className="brands-item">
                                    <LazyLoadImage
                                        src={imgLoaded ? img : placeholder}
                                        alt="axitec logo"
                                        className='brands-logo1 lazy2'
                                        onLoad={() => setImgLoaded(true)}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="brands-item">
                                    <LazyLoadImage
                                        src={img2Loaded ? img2 : placeholder}
                                        alt="Rec logo"
                                        className='brands-logo3 lazy2'
                                        onLoad={() => setImg2Loaded(true)}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="brands-item">
                                    <LazyLoadImage
                                        src={img1Loaded ? img1 : placeholder}
                                        alt="Anchor by panasonic"
                                        className='brands-logo2 lazy2'
                                        onLoad={() => setImg1Loaded(true)}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="brands-item">
                                    <LazyLoadImage
                                        src={img3Loaded ? img3 : placeholder}
                                        alt="Livguard logo"
                                        className='brands-logo4 lazy2'
                                        onLoad={() => setImg3Loaded(true)}
                                    />
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
                <div className="brands-work">
                    <div className="brands-work-item">
                        <div className="brands-work-item-logo">
                            <LazyLoadImage
                                src={img4Loaded ? img4 : placeholder}
                                alt="Demand-Driven Response"
                                className='lazy2'
                                onLoad={() => setImg4Loaded(true)}
                            />
                        </div>
                        <div className="brands-work-item-content">
                            <h4>₹ 30+ Cr</h4>
                            <p>Customers saved on electricity by installing solar plants.</p>
                        </div>
                    </div>
                    <div className="brands-work-item">
                        <div className="brands-work-item-logo">
                            <LazyLoadImage
                                src={img5Loaded ? img5 : placeholder}
                                alt="Users"
                                className='lazy2'
                                onLoad={() => setImg5Loaded(true)}
                            />
                        </div>
                        <div className="brands-work-item-content">
                            <h4>150+ Users</h4>
                            <p>Happy customers we are working with.</p>
                        </div>
                    </div>
                    <div className="brands-work-item">
                        <div className="brands-work-item-logo">
                            <LazyLoadImage
                                src={img6Loaded ? img6 : placeholder}
                                alt="11.5 MW Solar plants"
                                className='lazy2'
                                onLoad={() => setImg6Loaded(true)}
                            />
                        </div>
                        <div className="brands-work-item-content">
                            <h4>11.5 MW</h4>
                            <p>Solar plants successfully installed across Rajasthan.</p>
                        </div>
                    </div>
                    <div className="brands-work-item">
                        <div className="brands-work-item-logo">
                            <LazyLoadImage
                                src={img7Loaded ? img7 : placeholder}
                                alt="Happy customers"
                                className='lazy2'
                                onLoad={() => setImg7Loaded(true)}
                            />
                        </div>
                        <div className="brands-work-item-content">
                            <h4>13+ Years Experience</h4>
                            <p>We have been actively working in the same industry for the past 13 years.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurBrandsSection;
