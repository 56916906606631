import React from 'react';
import "./WhyChooseItem.css"

const WhyChooseItem = (props) => {
    return (
        <div className="why-choose-us-item">
            <div className="why-choose-img">
                <img src={props.img} alt="why choose logo" />
            </div>
            <div className="why-choose-content">
                <p className='why-c-h'>{props.heading}</p>
                <p className='why-c-d'>{props.desc}</p>
            </div>
        </div>
    )
}

export default WhyChooseItem