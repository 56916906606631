import React, { useState, useEffect, useMemo } from 'react';
import "./Ppa.css";
import { Helmet } from 'react-helmet-async';

import Breadcrumb from '../../components/BreadCrumb/BreadCrumb';

import ppa5 from "../../assets/ppa/ppa-img6.webp";

import EnquiryModal from '../../components/EnquiryModal/EnquiryModal';


const Ppa = () => {
    const [ppaData, setPpaData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setModalOpen] = useState(false);


    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://kesrinandan.com/admin/api/ppa/all');
                const result = await response.json();
                if (result.status === 1) {
                    setPpaData(result.data);
                } else {
                    console.error('Failed to retrieve data:', result.msg);
                }
            } catch (error) {
                console.error('Error fetching API data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const renderedSections = useMemo(() => {
        return ppaData.slice(0, 4).map((item, index) => (
            <div
                key={item?.id}
                className={`ppa-page-sec1 ${index % 2 === 0 ? "img-top" : "img-bottom"}`}
            >
                {index % 2 === 0 ? (
                    <>
                        <div className="page-content-left right-pad">
                            <h3 className='ppa-page-sec1-title'>{item?.title}</h3>
                            <div
                                className="ppa-page-sec1-desc"
                                dangerouslySetInnerHTML={{ __html: item?.description }}
                            ></div>
                        </div>
                        <div className="page-content-right">
                            <img src={item?.image} alt={item?.title} />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="page-content-right">
                            <img src={item?.image} alt={item?.title} />
                        </div>
                        <div className="page-content-left left-pad">
                            <h3 className='ppa-page-sec1-title'>{item?.title}</h3>
                            <div
                                className="ppa-page-sec1-desc"
                                dangerouslySetInnerHTML={{ __html: item?.description }}
                            ></div>
                        </div>
                    </>
                )}
            </div>
        ));
    }, [ppaData]);

    const section5Data = useMemo(() => {
        return ppaData.find((item) => item.section_name === "Section_5");
    }, [ppaData]);

    return (
        <>
            <Helmet>
                <title>Solar Power Purchase Agreements (PPA) | Kesrinandan</title>
                <meta
                    name="description"
                    content=" Discover the benefits of Power Purchase Agreements (PPA) solar solutions with Kesrinandan. Maximize energy savings and sustainability with our PPA options. Call now!"
                />
                <link rel="canonical" href="https://kesrinandan.com/ppa" />

                <meta property="og:url" content="https://kesrinandan.com/ppa" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Power Purchase Agreement (PPAs): Kesrinandan Ventures" />
                <meta property="og:description" content="Discover the benefits of Power Purchase Agreements (PPA) solar solutions with Kesrinandan. Maximize energy savings and sustainability with our PPA options. Call now!" />
                <meta property="og:image" content="https://kesrinandan.com/static/media/ppa-banner.8fecea0159f679ad1f77.webp" />

                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="kesrinandan.com" />
                <meta property="twitter:url" content="https://kesrinandan.com/ppa" />
                <meta name="twitter:title" content="Power Purchase Agreement (PPAs): Kesrinandan Ventures" />
                <meta name="twitter:description" content="Discover the benefits of Power Purchase Agreements (PPA) solar solutions with Kesrinandan. Maximize energy savings and sustainability with our PPA options. Call now!" />
                <meta name="twitter:image" content="https://kesrinandan.com/static/media/ppa-banner.8fecea0159f679ad1f77.webp" />

            </Helmet>

            <section className="about-banner ppa-banner">
                <div className="custom-text custom-text-about">
                    <h1 className='custom-title'>PPA<span className='color-dot'>.</span></h1>
                    <Breadcrumb paths={[{ label: 'Home', url: '/' }, { label: 'PPA', url: '/ppa' }]} />
                </div>
            </section>

            <section className="ppa-page-content">
                <div className="c-container">
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        renderedSections
                    )}
                </div>
            </section>

            <div className="ppa-page-sec2" style={{ backgroundImage: `url(${ppa5})` }}>
                <div className="c-container" >
                    <h2>Different Types Of Power Purchase Agreements</h2>
                    <div className="ppa-page-sec2-cards">
                        <div className="ppa-page-sec2-card">
                            <h3>Physical PPA</h3>
                            <p>The generator delivers electricity to the buyer physically.</p>
                        </div>
                        <div className="ppa-page-sec2-card">
                            <h3>Virtual PPA (VPPA)</h3>
                            <p>Instead of receiving actual electricity, the buyer receives financial gain from the project's renewable energy credits.</p>
                        </div>
                        <div className="ppa-page-sec2-card">
                            <h3>Sleeved PPA</h3>
                            <p>Involves using a third-party tool to "sleeve" or expedite the buyer-generator transaction.</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="ppa-page-sec3">
                <div className="c-container">
                    <div className="ppa-page-sec3-content">
                        {section5Data && (
                            <>
                                <h2>{section5Data?.title}</h2>
                                <div
                                    dangerouslySetInnerHTML={{ __html: section5Data?.description }}
                                ></div>
                                <button
                                    variant="primary"
                                    className="common-btn nav-btn"
                                    onClick={openModal}
                                    style={{ marginTop: "10px" }}
                                >
                                    Enquire Now
                                </button>
                            </>
                        )}
                    </div>
                    <div className="ppa-page-sec3-img">
                        {section5Data && (
                            <img src={section5Data?.image} alt={section5Data?.title} />
                        )}
                    </div>
                </div>
            </div>
            <EnquiryModal
                isOpen={isModalOpen} onClose={closeModal}
            />
        </>
    )
}

export default Ppa