import React, { useMemo, useState, useEffect, useContext } from 'react';
import "./KusumYojana.css"
import Breadcrumb from '../../components/BreadCrumb/BreadCrumb';
import kusum10 from "../../assets/kusum/kusum-10.svg"
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ReCAPTCHA from 'react-google-recaptcha';
import { AppContext } from '../../AppContext';

const NavbarHeight = 100;

const KusumYojana = () => {
  const { control, register, handleSubmit, reset, formState: { errors }, watch, setValue } = useForm({
    defaultValues: {
      enquiry_type: '0'
    }
  });
  const { states, city, setSelectedState } = useContext(AppContext);
  const selectedState = watch('state')
  useEffect(() => {
    setSelectedState(selectedState);
  }, [selectedState, setSelectedState]);
  const Navigate = useNavigate()
  const [captchaError, setCaptchaError] = useState(null);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [kusumData, setKusumData] = useState(null);
  const handleVerificationSuccess = (token) => {
    setCaptchaToken(token);
  };
  const handleLinkClick = (event, targetId) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      const targetPosition =
        targetElement.getBoundingClientRect().top + window.pageYOffset - NavbarHeight;

      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth',
      });
    }
  };
  const onSubmit = async (data) => {
    data.captchaToken = captchaToken;
    const formBody = new URLSearchParams(data).toString();
    try {
      const response = await fetch('https://kesrinandan.com/admin/api/quote-enquiry', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formBody,
      });
      const responseData = await response.json();
      if (response.ok) {
        reset()
        Navigate("/thank-you")
      } else {
        if (responseData.msg && responseData.msg === "Invalid CAPTCHA. Please try again") {
          setCaptchaError(responseData.msg);
        } else {
          console.error('Failed to submit the form:', responseData.error);
        }
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
    }
  };

  const steps = useMemo(() => [
    {
      title: "आधिकारिक वेबसाइट पर जाएँ",
      description: "PM-KUSUM योजना की आधिकारिक वेबसाइट या राज्य कार्यान्वयन एजेंसी के पोर्टल पर जाएँ।",
      bgColor: " rgb(255 243 224 / 48%)",
      textColor: "#f57c00"
    },
    {
      title: "आवेदन पत्र भरें",
      description: "ऑनलाइन आवेदन पत्र को पूरी तरह से भरें, जिसमें व्यक्तिगत और कृषि संबंधी जानकारी शामिल हो।",
      bgColor: "rgb(255 232 232 / 48%)",
      textColor: "#e53935"
    },
    {
      title: "आवश्यक दस्तावेज़ भेजें",
      description: "आवश्यक दस्तावेज़ अपलोड करें, जिसमें पहचान पत्र, संपत्ति के दस्तावेज़, और बैंक खाता जानकारी शामिल हो।",
      bgColor: "rgb(232 245 233 / 48%)",
      textColor: "#43a047"
    },
    {
      title: "आवेदन की समीक्षा",
      description: "संबंधित अधिकारी आवेदन की समीक्षा करेंगे। यदि स्वीकार कर लिया जाता है, तो आपको एक पुष्टि भेजी जाएगी।",
      bgColor: "rgb(243 229 245 / 48%)",
      textColor: "#8e24aa"
    },
    {
      title: "स्थापना और बोनस",
      description: "मंजूरी के बाद सौर पैनल या पंप स्थापित किया जाएगा, और कुसुम योजना की मानदंडों के अनुसार सब्सिडी प्रदान की जाएगी।",
      bgColor: "rgb(255 243 224 / 48%)",
      textColor: "#f57c00"
    }
  ], []);

  useEffect(() => {
    fetch('https://kesrinandan.com/admin/api/kusum/all')
      .then((response) => response.json())
      .then((data) => setKusumData(data.data))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  if (!kusumData) {
    return <div>Loading...</div>;
  }

  const mainHeaderData = kusumData.find(item => item.section_name === "main_header");
  const section1Data = kusumData.find(item => item.section_name === "section_1");
  const section2HeaderData = kusumData.find((item) => item.section_name === "section_2");
  const section2Data = kusumData.filter((item) =>
    item.section_name === "section_2_1" ||
    item.section_name === "section_2_2" ||
    item.section_name === "section_2_3" ||
    item.section_name === "section_2_4"
  );
  const section3HeaderData = kusumData.find((item) => item.section_name === "section_3");
  const section3Data = kusumData.filter((item) =>
    item.section_name === "section_3_1" ||
    item.section_name === "section_3_2" ||
    item.section_name === "section_3_3" ||
    item.section_name === "section_3_4"
  );
  const investProcessData = kusumData.find((item) => item.section_name === "section_4");

  const investStepsHeaderData = kusumData.find((item) => item.section_name === "section_5");
  const investStepsData = kusumData.filter((item) =>
    item.section_name === "section_5_1" ||
    item.section_name === "section_5_2" ||
    item.section_name === "section_5_3" ||
    item.section_name === "section_5_4" ||
    item.section_name === "section_5_5"
  );
  const section6Data = kusumData.find((item) => item.section_name === "section_6");
  return (
    <>
      <Helmet>
        <title>PM Kisan Kusum Solar Pump Yojna | Kesrinandan </title>
        <meta
          name="description"
          content="Learn about PM किसान कुसुम योजना and how Kusum Solar Yojana empowers farmers by providing subsidies for solar energy systems. Enquire Now!"
        />

        <link rel="canonical" href="https://kesrinandan.com/kusum-yojana" />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content="https://kesrinandan.com/kusum-yojana" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="PM Kisan Kusum Solar Pump Yojna | Kesrinandan " />
        <meta property="og:description" content="Learn about PM किसान कुसुम योजना and how Kusum Solar Yojana empowers farmers by providing subsidies for solar energy systems. Enquire Now!" />
        <meta property="og:image" content="https://kesrinandan.com/static/media/kusum-banner.8f56ef02e9a8e75a56e8.webp" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="kesrinandan.com" />
        <meta property="twitter:url" content="https://kesrinandan.com/kusum-yojana" />
        <meta name="twitter:title" content="PM Kisan Kusum Solar Pump Yojna | Kesrinandan " />
        <meta name="twitter:description" content="Learn about PM किसान कुसुम योजना and how Kusum Solar Yojana empowers farmers by providing subsidies for solar energy systems. Enquire Now!" />
        <meta name="twitter:image" content="https://kesrinandan.com/static/media/kusum-banner.8f56ef02e9a8e75a56e8.webp" />

      </Helmet>


      <section className="about-banner kusum-banner">
        <div className="custom-text custom-text-about">
          <p className='custom-title'>PM-KUSUM Scheme<span className='color-dot'>.</span></p>
          <Breadcrumb paths={[{ label: 'Home', url: '/' }, { label: 'PPA', url: '/ppa' }]} />
        </div>
      </section>


      <section className='kusum-main-section c-container'>
        <div className="kusum-main-header">
          <h1 className='kusum-header-small-title'>PM-KUSUM Scheme</h1>
          <p className='kusum-header-big-title'>{mainHeaderData?.title}</p>
          <p className='kusum-header-desc' dangerouslySetInnerHTML={{ __html: mainHeaderData?.description }} />
        </div>

        <div className="ksusum-main-content">
          <aside className="sidebar">
            <div className="sidebar-content">
              <ul>
                <li><span onClick={(event) => handleLinkClick(event, 'kusum-undestanding')} className='sidebar-link'>{section1Data?.title}</span></li>
                <li><span onClick={(event) => handleLinkClick(event, 'objectives')} className='sidebar-link'>{section2HeaderData.title}</span></li>
                <li><span onClick={(event) => handleLinkClick(event, 'components')} className='sidebar-link'>{section3HeaderData.title}</span></li>
                <li><span onClick={(event) => handleLinkClick(event, 'benefits')} className='sidebar-link'>Benefits Of PM-KUSUM Yojana</span></li>
                <li><span onClick={(event) => handleLinkClick(event, 'steps-section')} className='sidebar-link'>Steps to Register for the PM-KUSUM Scheme</span></li>
                <li><span onClick={(event) => handleLinkClick(event, 'initiative')} className='sidebar-link'>{investProcessData?.title}</span></li>
                <li><span onClick={(event) => handleLinkClick(event, 'invest')} className='sidebar-link'>{investStepsHeaderData?.title}</span></li>
              </ul>
              <div className="sidebar-cta">
                <h3>Go Solar Today! 🌞</h3>
                <p>Join the clean energy movement and start saving on your energy bills. Register your solar panels now and enjoy sustainable power for years to come!</p>
                <button>Register Now</button>
              </div>
            </div>
          </aside>

          <main className="kusum-content">
            <section className='kusum-undestanding' id='kusum-undestanding'>
              <img src={section1Data?.image} alt="Understanding PM-KUSUM" />
              <div className="understanding-content">
                <h2>{section1Data?.title}</h2>
                <p dangerouslySetInnerHTML={{ __html: section1Data?.description }} />
              </div>
            </section>

            <section id='objectives'>
              {section2HeaderData && (
                <section id="objectives">
                  <h2>{section2HeaderData?.title}</h2>
                  <div dangerouslySetInnerHTML={{ __html: section2HeaderData?.description }} />
                </section>
              )}

              {section2Data.length > 0 && (
                <div className="objectives">
                  <ul>
                    {section2Data?.map((objective) => (
                      <li key={objective?.id}>
                        <div className="objective-img">
                          <img src={objective?.image } alt={objective?.title} />
                        </div>
                        <div>
                          <h3>{objective?.title}</h3>
                          <div dangerouslySetInnerHTML={{ __html: objective?.description }} />
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </section>

            {section3HeaderData && (
              <section id="components">
                <h2>{section3HeaderData?.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: section3HeaderData?.description }} />
              </section>
            )}

            {section3Data.length > 0 && (
              <div className="components">
                {section3Data?.map((component) => (
                  <div className="component" key={component?.id}>
                    <div className="component-img">
                      <img src={component?.image || 'default-image.jpg'} alt={component?.title} />
                    </div>
                    <div className="component-info">
                      <h3>{component?.title}</h3>
                      <div dangerouslySetInnerHTML={{ __html: component?.description }} />
                    </div>
                  </div>
                ))}
              </div>
            )}

            <section id='benefits'>
              <h2>Benefits Of PM-KUSUM Yojana</h2>
              <p>For farmers, the PM Kisan KUSUM Yojana provides several advantages:</p>
              <div className="benefits">
                <div className="benefit">
                  <div className="benefit-img">
                    <img src={kusum10} alt="Benefit 1" />
                  </div>
                  <div className='benefit-heading'>
                    <h3>Subsidized Solar Pumps</h3>
                  </div>
                  <p>The Kusum Yojana subsidy allows farmers to get subsidies for solar pumps, bringing down the cost of solar energy.</p>
                </div>
                <div className="benefit">
                  <div className="benefit-img">
                    <img src={kusum10} alt="Benefit 1" />
                  </div>
                  <div className='benefit-heading'>
                    <h3>Energy Independence</h3>
                  </div>
                  <p>The Kusum Solar Panel Yojana's solar pumps guarantee a steady supply of electricity for irrigation, increasing crop yield.</p>
                </div>
                <div className="benefit">
                  <div className="benefit-img">
                    <img src={kusum10} alt="Benefit 1" />
                  </div>
                  <div className='benefit-heading'>
                    <h3>Cost Savings</h3>
                  </div>
                  <p>किसान सौर ऊर्जा में परिवर्तित होकर अपनी बिजली की लागत को महत्वपूर्ण रूप से कम कर सकते हैं।</p>
                </div>
                <div className="benefit">
                  <div className="benefit-img">
                    <img src={kusum10} alt="Benefit 1" />
                  </div>
                  <div className='benefit-heading'>
                    <h3>Income Generation</h3>
                  </div>
                  <p>By selling excess electricity to the grid under the PM Kisan Kusum Yojana, farmers can generate additional revenue.</p>
                </div>
                <div className="benefit">
                  <div className="benefit-img">
                    <img src={kusum10} alt="Benefit 1" />
                  </div>
                  <div className='benefit-heading'>
                    <h3>Environmental Benefits</h3>
                  </div>
                  <p>सौर ऊर्जा का उपयोग सतत कृषि पद्धतियों को बढ़ावा देने और कार्बन उत्सर्जन को कम करने में योगदान करता है।</p>
                </div>
              </div>
            </section>

            <section className="steps-section" id='steps-section'>
              <h2>Steps to Register for The PM-KUSUM Scheme</h2>
              <p>PM-KUSUM योजना के लिए साइन अप करने के लिए, निम्नलिखित चरणों का पालन करें:</p>
              <div className="steps-container">
                {steps.map((step, index) => (
                  <div className="step-card" key={index} style={{ backgroundColor: step.bgColor }}>
                    <h3 style={{ color: step.textColor }}>{step.title}</h3>
                    <p>{step.description}</p>
                  </div>
                ))}
              </div>
            </section>

            <section className='kusum-undestanding initiative' id='initiative'>
              <div className="understanding-content">
                <h2>{investProcessData?.title}</h2>
                <p dangerouslySetInnerHTML={{ __html: investProcessData?.description }} />
              </div>
              <img src={investProcessData?.image} alt="Understanding PM-KUSUM" />
            </section>

            <section id="invest">
              <h2>{investStepsHeaderData?.title}</h2>
              <div className="objectives invest">
                <ul>
                  {investStepsData?.map((step, index) => (
                    <li key={index}>
                      <div className="objective-img invest-img">
                        <img src={step?.image} alt={step?.title} />
                      </div>
                      <div>
                        <h3>{step?.title}</h3>
                        <p dangerouslySetInnerHTML={{ __html: step?.description }} />
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </section>
          </main>
        </div>
      </section>

      <section className="info-cta-section">
        <div className="info-cta-section-content c-container">
          <div className="info-cta-content">
            <div className="info-cta-image">
              <img src={section6Data?.image || 'default-image.jpg'} alt="Sustainable Agriculture" />
            </div>
            <div className="info-cta-text">
              <p>{section6Data?.title}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="inner-contact-form">
        <div className="inner-contact-form-content c-container">
          <div className="inner-contact-form-heading">
            Contact Us
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="vertical-form">
            <div className="form-container">
              <div className="form-group">
                <input type="text" className='form-control' placeholder='Your name' {...register('name', { required: 'Required' })} />
                {errors.name && <label className="error-message">{errors.name.message}</label>}
              </div>

              <div className="form-group">
                <input type="email" className='form-control' placeholder='Email' {...register('email', { required: 'Required', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Invalid email' } })} />
                {errors.email && <label className="error-message">{errors.email.message}</label>}
              </div>

              <div className="form-group">
                <input type="tel" className='form-control' placeholder='Phone number' {...register('phone', {
                  required: 'Required', pattern: {
                    value: /^\+?\d{1,3}(\s?\d{6,13})?$/,
                    message: 'Invalid phone number',
                  }
                })} />
                {errors.phone && <label className="error-message">{errors.phone.message}</label>}
              </div>

              <div className="form-group">
                <Controller
                  name="service_name"
                  defaultValue={""}
                  control={control}
                  rules={{ required: 'Please select a service' }}
                  render={({ field }) => (
                    <select {...field} className='form-control' onChange={(e) => setValue('service_name', e.target.value)}>
                      <option value="" disabled>Select a service</option>
                      <option value="Solar Panels">Solar Panels</option>
                      <option value="Solar Panels">Inverters</option>
                      <option value="Solar Panels">Cable Wires</option>
                      <option value="Solar Panels">PPA</option>
                    </select>
                  )}
                />
                {errors.service_name && <label className="error-message">{errors.service_name.message}</label>}

              </div>

              <div className="form-group">
                <Controller
                  name="state"
                  defaultValue={""}
                  control={control}
                  rules={{ required: 'Please select a state' }}
                  render={({ field }) => (
                    <select
                      className='form-control'
                      {...field} onChange={(e) => setValue('state', e.target.value)}>
                      <option value="" disabled>State</option>
                      {states?.map((el) => {
                        return (
                          <option key={el?.id} value={el?.id}>
                            {el?.state}
                          </option>
                        )
                      })}
                    </select>
                  )}
                />
                {errors.state && <label className="error-message">{errors.state.message}</label>}

              </div>

              <div className="form-group">
                <Controller
                  name="city"
                  defaultValue={""}
                  control={control}
                  rules={{ required: 'Please select a city' }}
                  render={({ field }) => (
                    <select
                      className='form-control'
                      {...field} onChange={(e) => setValue('city', e.target.value)}>
                      <option value="" disabled>City</option>
                      {city?.map((el) => {
                        return (
                          <option key={el?.id} value={el?.id}>
                            {el?.city_name}
                          </option>
                        )
                      })}
                    </select>
                  )}
                />
                {errors.city && <label className="error-message">{errors.city.message}</label>}

              </div>

              <div className="form-group">
                <Controller
                  name="type"
                  defaultValue={""}
                  control={control}
                  rules={{
                    required: 'Please select a type'
                  }}

                  render={({ field }) => (
                    <select {...field} className='form-control' onChange={(e) => setValue('type', e.target.value)}>
                      <option value="" disabled>Select a type</option>
                      <option value="dealer">Dealer</option>
                      <option value="retailer">Retailer</option>
                      <option value="distributor">Distributor</option>
                      <option value="epc player">EPC Player</option>
                      <option value="consumer">Consumer</option>
                    </select>
                  )}
                />
                {errors.type && <div className="error-message">{errors.type.message}</div>}
              </div>

              <div className="form-group" >
                <textarea placeholder='Your message' className='form-control' {...register('message', { required: 'Required' })} />
                {errors.message && <label className="error-message">{errors.message.message}</label>}
              </div>

              <input type="hidden" {...register('enquiry_type')} />

              <div className="form-group">
                <ReCAPTCHA
                  sitekey="6Lc5zpQpAAAAAO9suPoYtJaeB5r57wNwKbkCbSjX"
                  onChange={handleVerificationSuccess}
                />
                {captchaError && <div className="error-message">{captchaError}</div>}
              </div>

            </div>
            <div className="form-footer">
              <button className='common-btn enquiry-btn' type="submit">Submit</button>
            </div>
          </form>
        </div>
      </section>

    </>
  )
}

export default KusumYojana