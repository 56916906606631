import React, { useState, useEffect } from 'react';
import "./Products.css";
import { ColorRing } from 'react-loader-spinner';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from '../../components/BreadCrumb/BreadCrumb';
import { Link, useParams } from 'react-router-dom';
import NotFoundPage from '../../components/NotFoundPage/NotFoundPage';
import { HashLink } from 'react-router-hash-link';



const Product = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [title, setTitle] = useState('');
    const { slug } = useParams()
    const scrollTo = () => {
        window.scroll(0, 0)
    }

    useEffect(() => {
        if (slug === 'solar-panels') {
            setTitle('Commercial Solar Panels');
        } else if (slug === 'inverters') {
            setTitle('Solar Inverter Distributor');
        } else if (slug === 'cables-wires') {
            setTitle('Super Quality Cables & Wires');
        } else {
            setTitle(`Super Quality ${data?.title}`);
        }
    }, [slug, data?.title]);


    useEffect(() => {
        const fetchData = async () => {
            setError(null);
            try {
                const response = await fetch(`https://kesrinandan.com/admin/api/category/details?slug=${slug}`)
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (!result?.data) {
                    throw new Error('Invalid slug');
                }
                setData(result?.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [slug]);

    if (error) {
        return <NotFoundPage />;
    }

    const scrollWithOffset = (el, offset) => {
        const elementPosition = el.getBoundingClientRect().top + window.pageYOffset - offset;
        window.scroll({
            top: elementPosition,
        });
    };


    const titleData = {
        battery: {
          livguard: "Livguard Battery",
          eastman: "Eastman Battery",
        },
        "solar-panels": {
          livguard: "Livguard Solar Panels",
          eastman: "Eastman Solar Panels",
        },
        inverters: {
          panasonic: "Panasonic Solar Inverters",
          livguard: "Livguard Solar Inverters",
          eastman: "Eastman Solar Inverters",
        },
      };
      
      function getDynamicHeading(slug, partner) {
        return titleData[slug]?.[partner.slug] || partner?.title;
      }

    return (
        <>
            <Helmet>
                <title>{data?.meta_title}</title>
                <meta
                    name="description"
                    content={data?.meta_description}
                />
                <link rel="canonical" href={`https://kesrinandan.com/product/${slug}`} />

                <meta property="og:url" content={`https://kesrinandan.com/product/${slug}`} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={data?.meta_title} />
                <meta property="og:description" content={data?.meta_description} />
                <meta property="og:image" content="https://kesrinandan.com/static/media/products%20banner.788521c54f42411a4671.webp" />

                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="kesrinandan.com" />
                <meta property="twitter:url" content={`https://kesrinandan.com/product/${slug}`} />
                <meta name="twitter:title" content={data?.meta_title} />
                <meta name="twitter:description" content={data?.meta_description} />
                <meta name="twitter:image" content="https://kesrinandan.com/static/media/products%20banner.788521c54f42411a4671.webp" />
            </Helmet>

            <section className={`about-banner product-banner product-banner-${slug}`}>
                <div className="custom-text custom-text-product">
                    <p className='custom-title'>
                        {title}
                        <span className='color-dot'>.</span>
                    </p>
                    <Breadcrumb paths={[{ label: 'Home', url: '/' }, { label: `${slug}`, url: `/product/${slug}` }]} />
                </div>
            </section>


            <section className="products-section">
                <div className="c-container">
                    {loading ? (
                        <ColorRing
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={['#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D']}
                        />
                    ) : (
                        <>
                            <h1 className='products-t'>{data?.title}</h1>
                            <p>{data?.short_description}</p>
                            <div className="products-container2">
                                {data?.partner_data?.map((partner) => {
                                    return (partner?.products?.length > 0 && (
                                        <div className='products-by-category-container' key={partner.id}>
                                            <h2>{getDynamicHeading(data?.slug, partner)}</h2>
                                            <div className='p-description' dangerouslySetInnerHTML={{ __html: `${partner?.description}` }} />
                                            <div className="products-by-category products-container">
                                                {partner?.products?.slice(0, 3).map((el, index) => {
                                                    return (
                                                        <div className="products-item" key={index} onClick={() => scrollTo()}>
                                                            <Link to={`/product-detail/${el?.slug}`}>
                                                                <div className="products-item-img">
                                                                    <img src={el?.image} alt={el?.title} />
                                                                </div>
                                                                <h5>{el?.title}</h5>
                                                            </Link>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <HashLink className="common-btn p-btn" to={`/brand-detail/${partner?.slug}#${data?.id}`} scroll={el => scrollWithOffset(el, 100)}>Find More</HashLink>
                                        </div>
                                    ));
                                })}

                                {data?.without_partner?.length > 0 && (
                                    <div className='products-by-category-container without-partner'>
                                        <div className="products-by-category products-container">
                                            {data?.without_partner?.map((el, index) => {
                                                return (
                                                    <div className="products-item" key={index} onClick={() => scrollTo()}>
                                                        <Link to={`/product-detail/${el?.slug}`}>
                                                            <div className="products-item-img">
                                                                <img src={el?.image} alt={el?.title} />
                                                            </div>
                                                            <h5>{el?.title}</h5>
                                                        </Link>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='category-content-section' dangerouslySetInnerHTML={{ __html: `${data?.description}` }} />
                        </>
                    )}
                </div>
            </section>
        </>
    )
}

export default Product