import React, { useState, useEffect, useContext } from 'react';
import "./BrandDetail.css"
import { useParams } from 'react-router-dom';
import { ColorRing } from 'react-loader-spinner';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from '../../components/BreadCrumb/BreadCrumb';
// import CtaSection from '../../components/CtaSection/CtaSection';
import * as Accordion from '@radix-ui/react-accordion';
import classNames from 'classnames';
import { FaPlus, FaMinus } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import partnerSvg from "../../assets/become-partner-vector.svg"

import { RiMapPin4Line } from "react-icons/ri";
import { LuUser2 } from "react-icons/lu";
import { FiPhone } from "react-icons/fi";
import { LiaCitySolid } from "react-icons/lia";
import { VscTypeHierarchySub } from "react-icons/vsc";

import NotFoundPage from '../../components/NotFoundPage/NotFoundPage';
import { AppContext } from '../../AppContext';

import Axitec from "../../assets/brands/axitec-banner.webp";
import Rec from "../../assets/brands/rec-banner.webp";
import Livguard from "../../assets/brands/livguard-banner.webp";
import Panasonic from "../../assets/brands/panasonic-banner.png";
import Eastman from "../../assets/brands/eastman-banner.webp";
import DefaultImg from "../../assets/brand-banner.png";

const BrandDetail = () => {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [captchaError, setCaptchaError] = useState(null);
    const [captchaToken, setCaptchaToken] = useState(null);
    const Navigate = useNavigate()
    const { slug } = useParams()
    // const { control, register, handleSubmit, reset, formState: { errors }, watch, setValue } = useForm({
    //     defaultValues: {
    //         enquiry_type: '0'
    //     }
    // });

    // First form useForm
    const { control: controlForm1, register: registerForm1, handleSubmit: handleSubmitForm1, reset: resetForm1, formState: { errors: errorsForm1 }, watch: watchForm1, setValue: setValueForm1 } = useForm({
        defaultValues: {
            enquiry_type: '0',
        }
    });

    // Second form useForm
    const { control: controlForm2, register: registerForm2, handleSubmit: handleSubmitForm2, reset: resetForm2, formState: { errors: errorsForm2 }, watch: watchForm2, setValue: setValueForm2 } = useForm();

    const { states, city, setSelectedState } = useContext(AppContext);
    // const selectedState = watch('state')
    // useEffect(() => {
    //     setSelectedState(selectedState);
    // }, [selectedState, setSelectedState]);

    // Watch the state fields for both forms
    const selectedStateForm1 = watchForm1('state');  // Form 1
    const selectedStateForm2 = watchForm2('state');  // Form 2

    // Update the context state when state changes for either form
    useEffect(() => {
        if (selectedStateForm1) {
            setSelectedState(selectedStateForm1);  // For Form 1
        }
        if (selectedStateForm2) {
            setSelectedState(selectedStateForm2);  // For Form 2
        }
    }, [selectedStateForm1, selectedStateForm2, setSelectedState]);

    const handleVerificationSuccess = (token) => {
        setCaptchaToken(token);
    };


    const images = {
        axitec: Axitec,
        livguard: Livguard,
        rec: Rec,
        panasonic: Panasonic,
        eastman: Eastman,
    };

    const defaultImage = DefaultImg;

    const imageSrc = images[slug] || defaultImage;

    const AccordionTrigger = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
        <Accordion.Header className="AccordionHeader">
            <Accordion.Trigger
                className={classNames('AccordionTrigger', className)}
                {...props}
                ref={forwardedRef}
            >
                {children}
                <FaPlus className={` toggle-on`} />
                <FaMinus className={`toggle-off`} />
            </Accordion.Trigger>
        </Accordion.Header>
    ));

    const AccordionContent = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
        <Accordion.Content
            className={classNames('AccordionContent', className)}
            {...props}
            ref={forwardedRef}
        >
            <div className="AccordionContentText">{children}</div>
        </Accordion.Content>
    ));

    const groupProductsByCategoryId = (products) => {
        const grouped = products.reduce((acc, product) => {
            const categoryId = product.category_id;
            if (!acc[categoryId]) {
                acc[categoryId] = [];
            }
            acc[categoryId].push(product);
            return acc;
        }, {});
        return grouped;
    };

    const renderProductsByCategoryId = (products) => {
        const groupedProducts = groupProductsByCategoryId(products);
        const categoryNames = {
            108: 'Battery',
            29: 'Inverter',
            30: 'Solar Panel',
        };

        return Object.keys(groupedProducts).map((categoryId, index) => (
            <div key={index} id={categoryId}>
                <h2 className='product-ct'>{categoryNames[categoryId]}</h2>
                <div className='brand-products'>
                    {groupedProducts[categoryId].map((product) => (
                        <div className="products-item" key={product.id}>
                            <Link to={`/product-detail/${product.slug}`}>
                                <div className="products-item-img">
                                    <img src={product.image} alt="" />
                                </div>
                                <h5>{product.title}</h5>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        ));
    };


    useEffect(() => {
        const fetchData = async () => {
            setError(null);
            try {
                const response = await fetch(`https://kesrinandan.com/admin/api/partner/details?slug=${slug}`)
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (!result?.data) {
                    throw new Error('Invalid slug');
                }
                setData(result?.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [slug]);

    if (error) {
        return <NotFoundPage />;
    }

    // const onSubmit = async (data) => {
    //     data.captchaToken = captchaToken;
    //     const formBody = new URLSearchParams(data).toString();
    //     try {
    //         const response = await fetch('https://kesrinandan.com/admin/api/quote-enquiry', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/x-www-form-urlencoded',
    //             },
    //             body: formBody,
    //         });
    //         const responseData = await response.json();
    //         if (response.ok) {
    //             reset()
    //             Navigate("/thank-you")
    //         } else {
    //             if (responseData.msg && responseData.msg === "Invalid CAPTCHA. Please try again") {
    //                 setCaptchaError(responseData.msg);
    //             } else {
    //                 console.error('Failed to submit the form:', responseData.error);
    //             }
    //         }
    //     } catch (error) {
    //         console.error('Error submitting the form:', error);
    //     }
    // };

    // const Submit = async (data) => {
    //     data.captchaToken = captchaToken;
    //     const formBody = new URLSearchParams(data).toString();
    //     try {
    //         const response = await fetch('https://kesrinandan.com/admin/api/landingpageform', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/x-www-form-urlencoded',
    //             },
    //             body: formBody,
    //         });

    //         const responseData = await response.json();

    //         if (response.ok) {
    //             reset()
    //             Navigate("/thank-you")
    //         } else {
    //             if (responseData.msg && responseData.msg === "Invalid CAPTCHA. Please try again") {
    //                 setCaptchaError(responseData.msg);
    //             } else {
    //                 console.error('Failed to submit the form:', responseData.error);
    //             }
    //         }
    //     } catch (error) {
    //         console.error('Error submitting the form:', error);
    //     }
    // };


    // Submit function for Form 1
    const onSubmitForm1 = async (data) => {
        data.captchaToken = captchaToken;
        const formBody = new URLSearchParams(data).toString();

        try {
            const response = await fetch('https://kesrinandan.com/admin/api/quote-enquiry', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formBody,
            });

            const responseData = await response.json();
            if (response.ok) {
                resetForm1();
                Navigate("/thank-you");
            } else {
                if (responseData.msg && responseData.msg === "Invalid CAPTCHA. Please try again") {
                    setCaptchaError(responseData.msg);
                } else {
                    console.error('Failed to submit the form:', responseData.error);
                }
            }
        } catch (error) {
            console.error('Error submitting the form:', error);
        }
    };

    // Submit function for Form 2
    const onSubmitForm2 = async (data) => {
        data.captchaToken = captchaToken;
        const formBody = new URLSearchParams(data).toString();

        try {
            const response = await fetch('https://kesrinandan.com/admin/api/landingpageform', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formBody,
            });

            const responseData = await response.json();
            if (response.ok) {
                resetForm2();
                Navigate("/thank-you");
            } else {
                if (responseData.msg && responseData.msg === "Invalid CAPTCHA. Please try again") {
                    setCaptchaError(responseData.msg);
                } else {
                    console.error('Failed to submit the form:', responseData.error);
                }
            }
        } catch (error) {
            console.error('Error submitting the form:', error);
        }
    };

    // Form 1 submit handler
    const handleForm1Submit = (data) => {
        onSubmitForm1(data);
    };

    // Form 2 submit handler
    const handleForm2Submit = (data) => {
        onSubmitForm2(data);
    };


    return (
        <>
            <Helmet>
                <title>{data?.meta_title}</title>
                <meta
                    name="description"
                    content={data?.meta_description}
                />
                <link rel="canonical" href={`https://kesrinandan.com/brand-detail/${slug}`} />

                {/* Facebook Meta Tags */}
                <meta property="og:url" content={`https://kesrinandan.com/brand-detail/${slug}`} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={data?.meta_title} />
                <meta property="og:description" content={data?.meta_description} />
                <meta property="og:image" content="https://kesrinandan.com/static/media/brand-detail-banner.9346f140c12dd52ee73d.webp" />

                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="kesrinandan.com" />
                <meta property="twitter:url" content={`https://kesrinandan.com/brand-detail/${slug}`} />
                <meta name="twitter:title" content={data?.meta_title} />
                <meta name="twitter:description" content={data?.meta_description} />
                <meta name="twitter:image" content="https://kesrinandan.com/static/media/brand-detail-banner.9346f140c12dd52ee73d.webp" />

            </Helmet>


            <section className="brands-detail-banner">
                <div className="brand-detail-img">
                    <img src={imageSrc} alt={`${slug}`} />
                </div>
                <div className="custom-text custom-text-about">
                    <p className='custom-title' style={{ textTransform: "capitalize" }}>
                        {slug}
                        {slug && <span className='color-dot'>.</span>}
                    </p>
                    <Breadcrumb paths={[
                        { label: 'Home', url: '/' },
                        { label: `${slug}`, url: `/brand-detail/${slug}` }
                    ]} />
                </div>
            </section>

            <section className="brand-detail-section">

                {loading ? <ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={['#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D',]}
                /> : (
                    <>
                        <div>
                            <div className="brand-logo">
                                <h2>{data?.title}</h2>
                            </div>
                            <div className="brands-desc">
                                < div dangerouslySetInnerHTML={{ __html: `${data?.top_description}` }} />
                            </div>
                        </div>
                        {slug === 'livguard' || slug === 'eastman' ? (
                            <>
                                {renderProductsByCategoryId(data?.products_data)}
                            </>
                        ) : (
                            <div className='brand-products'>{
                                data?.products_data?.map((el) => (
                                    <div className="products-item" key={el?.id}>
                                        <Link to={`/product-detail/${el?.slug}`}>
                                            <div className="products-item-img">
                                                <img src={el?.image} alt={el?.title} />
                                            </div>
                                            <h5>{el?.title}</h5>
                                        </Link>
                                    </div>
                                ))
                            }</div>
                        )}

                        {/* <CtaSection /> */}

                        <div className="brands-desc brand-desc-2">
                            {loading ? <ColorRing
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={['#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D',]}
                            /> : < div dangerouslySetInnerHTML={{ __html: `${data?.bottom_description}` }} />
                            }
                        </div>

                    </>
                )}
            </section>


            {/* New Form Section */}
            <div className="landing-page-section-bg">
                <div className="c-container">
                    <section className="become-partner">
                        <div className="partner-content">
                            <div className="partner-info">
                                <h2>Become a Dealer</h2>
                                <p>Join Kesrinandan Ventures today and start your own business with our solar panel dealership program. This is the best opportunity to expand your business in the rapidly growing solar industry in various cities. Sign up today and profit from the highly demanding solar energy solutions.</p>
                            </div>
                            <img src={partnerSvg} alt="Descriptive Alt Text" className="partner-image" />
                        </div>
                        <div className="partner-form">
                            <form onSubmit={handleSubmitForm1(handleForm1Submit)} className="vertical-form">
                                <div className="form-container form-container2">
                                    {/* Name Field */}
                                    <div className="form-group">
                                        <div className="icon">
                                            <LuUser2 />
                                        </div>
                                        <Controller
                                            name="name"
                                            control={controlForm1}
                                            rules={{ required: 'Required' }}
                                            render={({ field }) => (
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Your name"
                                                    {...field}
                                                />
                                            )}
                                        />
                                        {errorsForm1.name && <div className="error-message">{errorsForm1.name.message}</div>}
                                    </div>

                                    {/* Phone Field */}
                                    <div className="form-group">
                                        <div className="icon">
                                            <FiPhone />
                                        </div>
                                        <Controller
                                            name="phone"
                                            control={controlForm1}
                                            rules={{
                                                required: 'Required',
                                                pattern: {
                                                    value: /^\+?\d{1,3}(\s?\d{6,13})?$/,
                                                    message: 'Invalid phone number',
                                                },
                                            }}
                                            render={({ field }) => (
                                                <input
                                                    type="tel"
                                                    className="form-control"
                                                    placeholder="Phone number"
                                                    {...field}
                                                />
                                            )}
                                        />
                                        {errorsForm1.phone && <div className="error-message">{errorsForm1.phone.message}</div>}
                                    </div>

                                    {/* Product Field */}
                                    <div className="form-group">
                                        <div className="icon">
                                            <VscTypeHierarchySub />
                                        </div>
                                        <Controller
                                            name="product"
                                            defaultValue={""}
                                            control={controlForm1}
                                            rules={{ required: 'Please select Product' }}
                                            render={({ field }) => (
                                                <select {...field} className='form-control'>
                                                    <option value="" disabled>Select Product</option>
                                                    <option value="Solar Panels">Solar Panels</option>
                                                    <option value="Inverters">Inverters</option>
                                                    <option value="Cables Wires">Cables Wires</option>
                                                </select>
                                            )}
                                        />
                                        {errorsForm1.product && <div className="error-message">{errorsForm1.product.message}</div>}
                                    </div>

                                    {/* State Field */}
                                    <div className="form-group">
                                        <div className="icon">
                                            <RiMapPin4Line />
                                        </div>
                                        <Controller
                                            name="state"
                                            control={controlForm1}
                                            defaultValue={""}
                                            rules={{ required: 'Please select a state' }}
                                            render={({ field }) => (
                                                <select
                                                    {...field}
                                                    className="form-control"
                                                    onChange={(e) => setValueForm1('state', e.target.value)}
                                                >
                                                    <option value="" disabled>Select a state</option>
                                                    {states.map((el) => (
                                                        <option key={el.id} value={el.id}>
                                                            {el.state}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        />
                                        {errorsForm1.state && <div className="error-message">{errorsForm1.state.message}</div>}
                                    </div>

                                    {/* City Field */}
                                    <div className="form-group">
                                        <div className="icon">
                                            <LiaCitySolid />
                                        </div>
                                        <Controller
                                            name="city"
                                            defaultValue={""}
                                            control={controlForm1}
                                            rules={{ required: 'Please select a city' }}
                                            render={({ field }) => (
                                                <select
                                                    {...field}
                                                    className="form-control"
                                                    onChange={(e) => setValueForm1('city', e.target.value)}
                                                >
                                                    <option value="" disabled>Select a city</option>
                                                    {city?.map((el) => (
                                                        <option key={el?.id} value={el?.id}>
                                                            {el?.city_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        />
                                        {errorsForm1.city && <div className="error-message">{errorsForm1.city.message}</div>}
                                    </div>

                                    {/* Godown Availability Field */}
                                    <div className="form-group">
                                        <div className="icon">
                                            <VscTypeHierarchySub />
                                        </div>
                                        <Controller
                                            name="godown_available"
                                            defaultValue={""}
                                            control={controlForm1}
                                            rules={{ required: 'Please Select' }}
                                            render={({ field }) => (
                                                <select {...field} className="form-control">
                                                    <option value="" disabled>Is Godown Area Available?</option>
                                                    <option value="Y">Yes</option>
                                                    <option value="N">No</option>
                                                </select>
                                            )}
                                        />
                                        {errorsForm1.godown_available && (
                                            <div className="error-message">{errorsForm1.godown_available.message}</div>
                                        )}
                                    </div>

                                    {/* Interested Field */}
                                    <div className="form-group w-100">
                                        <div className="icon">
                                            <VscTypeHierarchySub />
                                        </div>
                                        <Controller
                                            name="interested"
                                            defaultValue={""}
                                            control={controlForm1}
                                            rules={{ required: 'Please Select' }}
                                            render={({ field }) => (
                                                <select {...field} className="form-control">
                                                    <option value="" disabled>Interested in submitting 10 Lac as a security amount?</option>
                                                    <option value="Y">Yes</option>
                                                    <option value="N">No</option>
                                                </select>
                                            )}
                                        />
                                        {errorsForm1.interested && (
                                            <div className="error-message">{errorsForm1.interested.message}</div>
                                        )}
                                    </div>

                                    {/* Capital Investment Field */}
                                    <div className="form-group w-100">
                                        <div className="icon">
                                            <VscTypeHierarchySub />
                                        </div>
                                        <Controller
                                            name="capital_invest"
                                            defaultValue={""}
                                            control={controlForm1}
                                            rules={{ required: 'Please Select' }}
                                            render={({ field }) => (
                                                <select {...field} className="form-control">
                                                    <option value="" disabled>How much capital can you invest? (Lac)</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                </select>
                                            )}
                                        />
                                        {errorsForm1.capital_invest && (
                                            <div className="error-message">{errorsForm1.capital_invest.message}</div>
                                        )}
                                    </div>

                                    {/* Message Field */}
                                    <div className="form-group text-area-group w-100">
                                        <textarea
                                            placeholder="Your message"
                                            className="form-control form-control-textarea"
                                            {...registerForm1('message', { required: 'Required' })}
                                        />
                                        {errorsForm1.message && (
                                            <div className="error-message">{errorsForm1.message.message}</div>
                                        )}
                                    </div>

                                    {/* reCAPTCHA */}
                                    <div className="form-group w-100">
                                        <ReCAPTCHA
                                            sitekey="6Lc5zpQpAAAAAO9suPoYtJaeB5r57wNwKbkCbSjX"
                                            onChange={handleVerificationSuccess}
                                        />
                                        {captchaError && <div className="error-message">{captchaError}</div>}
                                    </div>
                                </div>

                                {/* Submit Button */}
                                <div className="form-footer">
                                    <button className="common-btn enquiry-btn" type="submit">Submit Request</button>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>
            </div>



            <section className="faq-section" style={{paddingTop:"40px"}}>
                <div className="c-container">
                    <h2 className="description">If you have any questions, we will be very happy to answer them</h2>
                    <div className="faq-accordion-section">
                        <Accordion.Root className="AccordionRoot" type="single" collapsible>
                            {data?.dataAccordian?.map((el, index) => (
                                <Accordion.Item className="AccordionItem" key={index + 1} value={index + 1}>
                                    <AccordionTrigger>{el?.title}</AccordionTrigger>
                                    <AccordionContent>{el?.description}</AccordionContent>
                                </Accordion.Item>
                            ))}
                        </Accordion.Root>
                    </div>
                </div>
            </section>

            <section className="inner-contact-form">
                <div className="inner-contact-form-content c-container">
                    <div className="inner-contact-form-heading">
                        Contact Us
                    </div>
                    <form onSubmit={handleSubmitForm2(handleForm2Submit)} className="vertical-form">
                        <div className="form-container">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Your name"
                                    {...registerForm2('name', { required: 'Required' })}
                                />
                                {errorsForm2.name && <label className="error-message">{errorsForm2.name.message}</label>}
                            </div>

                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Email"
                                    {...registerForm2('email', {
                                        required: 'Required',
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: 'Invalid email'
                                        }
                                    })}
                                />
                                {errorsForm2.email && <label className="error-message">{errorsForm2.email.message}</label>}
                            </div>

                            <div className="form-group">
                                <input
                                    type="tel"
                                    className="form-control"
                                    placeholder="Phone number"
                                    {...registerForm2('phone', {
                                        required: 'Required',
                                        pattern: {
                                            value: /^\+?\d{1,3}(\s?\d{6,13})?$/,
                                            message: 'Invalid phone number',
                                        }
                                    })}
                                />
                                {errorsForm2.phone && <label className="error-message">{errorsForm2.phone.message}</label>}
                            </div>

                            <div className="form-group">
                                <Controller
                                    name="service_name"
                                    control={controlForm2}
                                    rules={{ required: 'Please select a service' }}
                                    render={({ field }) => (
                                        <select
                                            {...field}
                                            className="form-control"
                                            defaultValue={""}
                                            onChange={(e) => setValueForm2('service_name', e.target.value)}
                                        >
                                            <option value="" disabled>Select a service</option>
                                            <option value="Solar Panels">Solar Panels</option>
                                            <option value="Inverters">Inverters</option>
                                            <option value="Cable Wires">Cable Wires</option>
                                            <option value="PPA">PPA</option>
                                        </select>
                                    )}
                                />
                                {errorsForm2.service_name && <label className="error-message">{errorsForm2.service_name.message}</label>}
                            </div>

                            <div className="form-group">
                                <Controller
                                    name="state"
                                    control={controlForm2}
                                    rules={{ required: 'Please select a state' }}
                                    render={({ field }) => (
                                        <select
                                            className="form-control"
                                            defaultValue={""}
                                            {...field}
                                            onChange={(e) => setValueForm2('state', e.target.value)}
                                        >
                                            <option value="" disabled>State</option>
                                            {/* Assuming `states` is available */}
                                            {states?.map((el) => (
                                                <option key={el?.id} value={el?.id}>
                                                    {el?.state}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                />
                                {errorsForm2.state && <label className="error-message">{errorsForm2.state.message}</label>}
                            </div>

                            <div className="form-group">
                                <Controller
                                    name="city"
                                    defaultValue={""}
                                    control={controlForm2}
                                    rules={{ required: 'Please select a city' }}
                                    render={({ field }) => (
                                        <select
                                            className="form-control"
                                            {...field}
                                            onChange={(e) => setValueForm2('city', e.target.value)}
                                        >
                                            <option value="" disabled>City</option>
                                            {/* Assuming `city` is available */}
                                            {city?.map((el) => (
                                                <option key={el?.id} value={el?.id}>
                                                    {el?.city_name}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                />
                                {errorsForm2.city && <label className="error-message">{errorsForm2.city.message}</label>}
                            </div>

                            <div className="form-group">
                                <Controller
                                    name="type"
                                    defaultValue={""}
                                    control={controlForm2}
                                    rules={{ required: 'Please select a type' }}
                                    render={({ field }) => (
                                        <select
                                            {...field}
                                            className="form-control"
                                            onChange={(e) => setValueForm2('type', e.target.value)}
                                        >
                                            <option value="" disabled>Select a type</option>
                                            <option value="dealer">Dealer</option>
                                            <option value="retailer">Retailer</option>
                                            <option value="distributor">Distributor</option>
                                            <option value="epc player">EPC Player</option>
                                            <option value="consumer">Consumer</option>
                                        </select>
                                    )}
                                />
                                {errorsForm2.type && <div className="error-message">{errorsForm2.type.message}</div>}
                            </div>

                            <div className="form-group">
                                <textarea
                                    placeholder="Your message"
                                    className="form-control"
                                    {...registerForm2('message', { required: 'Required' })}
                                />
                                {errorsForm2.message && <label className="error-message">{errorsForm2.message.message}</label>}
                            </div>

                            <input type="hidden" {...registerForm2('enquiry_type')} />

                            <div className="form-group">
                                <ReCAPTCHA
                                    sitekey="6Lc5zpQpAAAAAO9suPoYtJaeB5r57wNwKbkCbSjX"
                                    onChange={handleVerificationSuccess}
                                />
                                {captchaError && <div className="error-message">{captchaError}</div>}
                            </div>
                        </div>

                        <div className="form-footer">
                            <button className="common-btn enquiry-btn" type="submit">Submit</button>
                        </div>
                    </form>
                </div>
            </section>

        </>
    )
}

export default BrandDetail